(function (t, i, e, s) {
    "use strict";

    function o(i, e) {
        this.element = i, this.$context = t(i).data("api", this), this.$layers = this.$context.find(".layer");
        var s = {
            calibrateX: this.$context.data("calibrate-x") || null,
            calibrateY: this.$context.data("calibrate-y") || null,
            invertX: this.$context.data("invert-x") || null,
            invertY: this.$context.data("invert-y") || null,
            limitX: parseFloat(this.$context.data("limit-x")) || null,
            limitY: parseFloat(this.$context.data("limit-y")) || null,
            scalarX: parseFloat(this.$context.data("scalar-x")) || null,
            scalarY: parseFloat(this.$context.data("scalar-y")) || null,
            frictionX: parseFloat(this.$context.data("friction-x")) || null,
            frictionY: parseFloat(this.$context.data("friction-y")) || null
        };
        for (var o in s) null === s[o] && delete s[o];
        t.extend(this, r, e, s), this.calibrationTimer = null, this.calibrationFlag = !0, this.enabled = !1, this.depths = [], this.raf = null, this.ox = 0, this.oy = 0, this.ow = 0, this.oh = 0, this.cx = 0, this.cy = 0, this.ix = 0, this.iy = 0, this.mx = 0, this.my = 0, this.vx = 0, this.vy = 0, this.onMouseMove = this.onMouseMove.bind(this), this.onDeviceOrientation = this.onDeviceOrientation.bind(this), this.onOrientationTimer = this.onOrientationTimer.bind(this), this.onCalibrationTimer = this.onCalibrationTimer.bind(this), this.onAnimationFrame = this.onAnimationFrame.bind(this), this.onWindowResize = this.onWindowResize.bind(this), this.initialise()
    }
    var n = "parallax",
        a = 30,
        r = {
            calibrationThreshold: 100,
            calibrationDelay: 500,
            supportDelay: 500,
            calibrateX: !1,
            calibrateY: !0,
            invertX: !0,
            invertY: !0,
            limitX: !1,
            limitY: !1,
            scalarX: 10,
            scalarY: 10,
            frictionX: .1,
            frictionY: .1
        };
    o.prototype.transformSupport = function (t) {
        for (var o = e.createElement("div"), n = !1, a = null, r = !1, h = null, l = null, p = 0, c = this.vendors.length; c > p; p++)
            if (null !== this.vendors[p] ? (h = this.vendors[p][0] + "transform", l = this.vendors[p][1] + "Transform") : (h = "transform", l = "transform"), o.style[l] !== s) {
                n = !0;
                break
            }
        switch (t) {
        case "2D":
            r = n;
            break;
        case "3D":
            n && (e.body.appendChild(o), o.style[l] = "translate3d(1px,1px,1px)", a = i.getComputedStyle(o).getPropertyValue(h), r = a !== s && a.length > 0 && "none" !== a, e.body.removeChild(o))
        }
        return r
    }, o.prototype.ww = null, o.prototype.wh = null, o.prototype.hw = null, o.prototype.hh = null, o.prototype.portrait = null, o.prototype.desktop = !navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|BB10|mobi|tablet|opera mini|nexus 7)/i), o.prototype.vendors = [null, ["-webkit-", "webkit"],
        ["-moz-", "Moz"],
        ["-o-", "O"],
        ["-ms-", "ms"]
    ], o.prototype.motionSupport = !! i.DeviceMotionEvent, o.prototype.orientationSupport = !! i.DeviceOrientationEvent, o.prototype.orientationStatus = 0, o.prototype.transform2DSupport = o.prototype.transformSupport("2D"), o.prototype.transform3DSupport = o.prototype.transformSupport("3D"), o.prototype.initialise = function () {
        "static" === this.$context.css("position") && this.$context.css({
            position: "relative"
        }), this.$layers.css({
            position: "absolute",
            display: "block",
            height: "100%",
            width: "100%",
            left: 0,
            top: 0
        }), this.$layers.first().css({
            position: "relative"
        }), this.$layers.each(t.proxy(function (i, e) {
            this.depths.push(t(e).data("depth") || 0)
        }, this)), this.accelerate(this.$context), this.accelerate(this.$layers), this.updateDimensions(), this.enable(), this.queueCalibration(this.calibrationDelay)
    }, o.prototype.updateDimensions = function () {
        this.ox = this.$context.offset().left, this.oy = this.$context.offset().top, this.ow = this.$context.width(), this.oh = this.$context.height(), this.ww = i.innerWidth, this.wh = i.innerHeight, this.hw = this.ww / 2, this.hh = this.wh / 2
    }, o.prototype.queueCalibration = function (t) {
        clearTimeout(this.calibrationTimer), this.calibrationTimer = setTimeout(this.onCalibrationTimer, t)
    }, o.prototype.enable = function () {
        this.enabled || (this.enabled = !0, this.orientationSupport ? (this.portrait = null, i.addEventListener("deviceorientation", this.onDeviceOrientation), setTimeout(this.onOrientationTimer, this.supportDelay)) : (this.cx = 0, this.cy = 0, this.portrait = !1, i.addEventListener("mousemove", this.onMouseMove)), i.addEventListener("resize", this.onWindowResize), this.raf = requestAnimationFrame(this.onAnimationFrame))
    }, o.prototype.disable = function () {
        this.enabled && (this.enabled = !1, this.orientationSupport ? i.removeEventListener("deviceorientation", this.onDeviceOrientation) : i.removeEventListener("mousemove", this.onMouseMove), i.removeEventListener("resize", this.onWindowResize), cancelAnimationFrame(this.raf))
    }, o.prototype.calibrate = function (t, i) {
        this.calibrateX = t === s ? this.calibrateX : t, this.calibrateY = i === s ? this.calibrateY : i
    }, o.prototype.invert = function (t, i) {
        this.invertX = t === s ? this.invertX : t, this.invertY = i === s ? this.invertY : i
    }, o.prototype.friction = function (t, i) {
        this.frictionX = t === s ? this.frictionX : t, this.frictionY = i === s ? this.frictionY : i
    }, o.prototype.scalar = function (t, i) {
        this.scalarX = t === s ? this.scalarX : t, this.scalarY = i === s ? this.scalarY : i
    }, o.prototype.limit = function (t, i) {
        this.limitX = t === s ? this.limitX : t, this.limitY = i === s ? this.limitY : i
    }, o.prototype.clamp = function (t, i, e) {
        return t = Math.max(t, i), t = Math.min(t, e)
    }, o.prototype.css = function (i, e, o) {
        for (var n = null, a = 0, r = this.vendors.length; r > a; a++)
            if (n = null !== this.vendors[a] ? t.camelCase(this.vendors[a][1] + "-" + e) : e, i.style[n] !== s) {
                i.style[n] = o;
                break
            }
    }, o.prototype.accelerate = function (t) {
        for (var i = 0, e = t.length; e > i; i++) {
            var s = t[i];
            this.css(s, "transform", "translate3d(0,0,0)"), this.css(s, "transform-style", "preserve-3d"), this.css(s, "backface-visibility", "hidden")
        }
    }, o.prototype.setPosition = function (t, i, e) {
        i += "%", e += "%", this.transform3DSupport ? this.css(t, "transform", "translate3d(" + i + "," + e + ",0)") : this.transform2DSupport ? this.css(t, "transform", "translate(" + i + "," + e + ")") : (t.style.left = i, t.style.top = e)
    }, o.prototype.onOrientationTimer = function () {
        this.orientationSupport && 0 === this.orientationStatus && (this.disable(), this.orientationSupport = !1, this.enable())
    }, o.prototype.onCalibrationTimer = function () {
        this.calibrationFlag = !0
    }, o.prototype.onWindowResize = function () {
        this.updateDimensions()
    }, o.prototype.onAnimationFrame = function () {
        var t = this.ix - this.cx,
            i = this.iy - this.cy;
        (Math.abs(t) > this.calibrationThreshold || Math.abs(i) > this.calibrationThreshold) && this.queueCalibration(0), this.portrait ? (this.mx = (this.calibrateX ? i : this.iy) * this.scalarX, this.my = (this.calibrateY ? t : this.ix) * this.scalarY) : (this.mx = (this.calibrateX ? t : this.ix) * this.scalarX, this.my = (this.calibrateY ? i : this.iy) * this.scalarY), isNaN(parseFloat(this.limitX)) || (this.mx = this.clamp(this.mx, -this.limitX, this.limitX)), isNaN(parseFloat(this.limitY)) || (this.my = this.clamp(this.my, -this.limitY, this.limitY)), this.vx += (this.mx - this.vx) * this.frictionX, this.vy += (this.my - this.vy) * this.frictionY;
        for (var e = 0, s = this.$layers.length; s > e; e++) {
            var o = this.depths[e],
                n = this.$layers[e],
                a = this.vx * o * (this.invertX ? -1 : 1),
                r = this.vy * o * (this.invertY ? -1 : 1);
            this.setPosition(n, a, r)
        }
        this.raf = requestAnimationFrame(this.onAnimationFrame)
    }, o.prototype.onDeviceOrientation = function (t) {
        if (!this.desktop && null !== t.beta && null !== t.gamma) {
            this.orientationStatus = 1;
            var e = (t.beta || 0) / a,
                s = (t.gamma || 0) / a,
                o = i.innerHeight > i.innerWidth;
            this.portrait !== o && (this.portrait = o, this.calibrationFlag = !0), this.calibrationFlag && (this.calibrationFlag = !1, this.cx = e, this.cy = s), this.ix = e, this.iy = s
        }
    }, o.prototype.onMouseMove = function (t) {
        this.ix = (t.pageX - this.hw) / this.hw, this.iy = (t.pageY - this.hh) / this.hh
    };
    var h = {
        enable: o.prototype.enable,
        disable: o.prototype.disable,
        calibrate: o.prototype.calibrate,
        friction: o.prototype.friction,
        invert: o.prototype.invert,
        scalar: o.prototype.scalar,
        limit: o.prototype.limit
    };
    t.fn[n] = function (i) {
        var e = arguments;
        return this.each(function () {
            var s = t(this),
                a = s.data(n);
            a || (a = new o(this, i), s.data(n, a)), h[i] && a[i].apply(a, Array.prototype.slice.call(e, 1))
        })
    }
})(window.jQuery || window.Zepto, window, document),
function () {
    for (var t = 0, i = ["ms", "moz", "webkit", "o"], e = 0; i.length > e && !window.requestAnimationFrame; ++e) window.requestAnimationFrame = window[i[e] + "RequestAnimationFrame"], window.cancelAnimationFrame = window[i[e] + "CancelAnimationFrame"] || window[i[e] + "CancelRequestAnimationFrame"];
    window.requestAnimationFrame || (window.requestAnimationFrame = function (i) {
        var e = (new Date).getTime(),
            s = Math.max(0, 16 - (e - t)),
            o = window.setTimeout(function () {
                i(e + s)
            }, s);
        return t = e + s, o
    }), window.cancelAnimationFrame || (window.cancelAnimationFrame = function (t) {
        clearTimeout(t)
    })
}();