var magicalPadding = 350;

$(document).ready(function(){

    // fix header for mobile / pad devices
    if ((/Android|iPhone|iPad|iPod|BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera)) {
        $('.top-header-holder').addClass('phoned');
    }

    // activate counter
    $('.count span').counterUp({
        delay: 10,
        time: 1000
    });

    // init owl slider
    $('.trust-us-slider').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        rewindNav: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        autoplayTimeout: 2200,
        responsive: {
            0: {
                items:1
            },
            400: {
                items:2
            },
            550: {
                items:3
            },
            1000: {
                items:4
            },
            1100: {
                items:5
            },
            1700: {
                items:7
            }
        }
    });

    // init parallax effect
    var brainLeftSide = $('.brain-left-side');
    $(window).on('load', function(){
        brainLeftSide.addClass('loaded');
        brainLeftSide.parallax();
    });

    // show people
    $('.single-person-holder, .people-holder, .people-holder-text').addClass('visible');

    // show below content when reached
    // var myHeader = $('header');
    var leftSidePerson = $('.left-side-person');
    var rightSection = $('.right-side-section');
    var quotesSlider = $('.quotes-slider');
    var redSection = $('.red-section');
    var rightSidePerson = $('.right-side-person');
    var mindsetTextSection = $('.mindset-text-section');
    var brainRightSide = $('.brain-right-side');
    var hptiSection = $('.hpti-section');
    var mindsetSection = $('.mindset-text-section-bottom');

    $(window).on('scroll', function() {

        if ( isScrolledIntoView(leftSidePerson) ) {
            leftSidePerson.addClass('visible');
        }

        if ( isScrolledIntoView(rightSection) ) {
            rightSection.addClass('visible');
        }

        if ( isScrolledIntoView(quotesSlider) ) {
            quotesSlider.addClass('visible');
        }

        if ( isScrolledIntoView(redSection) ) {
            redSection.addClass('visible');
        }

        if ( isScrolledIntoView(rightSidePerson) ) {
            rightSidePerson.addClass('visible');
        }

        if ( isScrolledIntoView(mindsetTextSection) ) {
            mindsetTextSection.addClass('visible');
        }

        if ( isScrolledIntoView(brainRightSide) ) {
            brainRightSide.addClass('visible');
        }

        if ( isScrolledIntoView(hptiSection) ) {
            hptiSection.addClass('visible');
        }

        if ( isScrolledIntoView(mindsetSection) ) {
            mindsetSection.addClass('visible');
        }

    });

    // homepage styling
    $('.potential-section a').addClass('button main-button').parent().addClass('two-button-holder');
    $('.single-counter a').addClass('button main-button');
    $('.we-help-you-section img').each(function(){
        $(this).parent().next().andSelf().wrapAll('<div class="single-help-you"></div>');
        $(this).wrap('<div class="help-you-circle"></div>');
    });
    $('.hpti-section a').addClass('button transparent-button');
    $('.brain-right-side a').wrapAll('<div class="brain-buttons"></div>')
    $('.brain-buttons').appendTo($('.leading-brain-section .center'));
    $('.brain-buttons a').addClass('button white-button');
    $('.home-last-section a').addClass('button main-button');
    $('.home-last-section a').parent().addClass('two-button-holder');

});

function isScrolledIntoView(elem)
{
    scroll = getScroll();
    var elemTop = $(elem).offset().top;

    return (scroll.top >= elemTop - $(window).outerHeight() / 1.25);
}